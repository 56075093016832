.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .checkmark1 {
    width: 20px;
    height: 20px;
    border: 1px solid white;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
  }
  
  .checkmark1::after {
    content: '';
    position: absolute;
    display: none;
    left: 2px;
    top: -2px;
    width: 15px;
    height: 18px;
    border: solid black;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  
  input[type="checkbox"]:checked + .checkmark1 {
    background-color: white;
  }
  
  input[type="checkbox"]:checked + .checkmark1::after {
    display: block;
  }
.m-node.placeholder>* {
    visibility: hidden;
}

.m-node.placeholder {
    border: 1px dashed #1385e5;
}

.m-node .inner {
    position: relative;
    cursor: pointer;
    padding-left: 10px;
    color: black;
    font-size: 12px;
}

.m-node .node {
    /* display: inline-block; */
    width: 100%;
    padding: 4px 5px;
}

.m-node .node:before {
    content: "I";
    width: 20px;
    height: 20px;
    border: 1px solid red;
}

.m-node .node.is-active {
    background-color: #31363f;
}

.f-no-select {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.m-tree {
    position: relative;
    overflow: hidden;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.m-draggable {
    position: absolute;
    opacity: 0.8;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.m-node .collapse {
    position: absolute;
    left: 0;
    cursor: pointer;
}

.m-node .caret-right:before {
    content: '\25B8';
}

.m-node .caret-down:before {
    content: '\25BE';
}
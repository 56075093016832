.wrap{
    position: relative;
    transition : all .1s;
    
}
.wrap.vaild{
    
    transition : all .1s;
}

.wrap.active{
    border-bottom: 2px solid var(--main-color);
    transition : all .1s;
}
.wrap.disabled{
    background-color: var(--user-bg-color) !important;
    
}

.placeHolder{
    position: absolute;
    top: -10px;
    color: var(--font-faint-color) ;
    transition : all .1s;
    padding: 0 0 0 20px;
}
.placeHolderText::placeholder{
    color: #D0D0D0; /* 원하는 placeholder 색상 */
    opacity: 0.8; /* IE에서의 색상 변경을 위해 */
}

.placeHolder:focus{
    
}

.placeHolder.active{
    font-weight: bold;
    font-size: 10.5px ;
    color: var(--font-color);
    transition : all .1s;
    top: 0 ;
}

.defaultPlaceHolder{
    top: 13px ;
    
}

.readOnly{
    font-weight: bold;
    font-size: 10.5px ;
    color: var(--font-color);
    transition : all .1s;
    top: -10px ;
    
}

.textArea{
    border-radius: 0;
}

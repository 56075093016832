.wrap {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height:100%;
}

.inner {
    display:flex;
    flex-direction: column;
    width:initial;
    height:initial;
    text-align: center;
}
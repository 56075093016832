:root {
  
  
  --main-color: #73FF8A;
  --bg-color : rgba(40, 40, 40, 1) ;
  
  

}
@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'GmarketSansBold';
  src: url('./font/GmarketSansTTFBold.ttf') format('truetype');
}

@keyframes countdownAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Toastify__toast-container {
  height: fit-content;
}

html, body {
  touch-action: manipulation;
  background-color: rgba(40, 40, 40, 1);
}

body {
  display: block;
  margin: 0px;
  /* background-color: var(--bg-color); */
  align-items: center;
  font-family: Pretendard !important;
  
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none; /* 크롬, 사파리, 오페라 */
}

div {
  /* border: 1px solid black; */
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  /* background-color: rgba(40, 40, 40, 1); */
}

ul {
  list-style: none;
  padding-inline-start: 0px;
  margin: 0px;
}

a {
  text-decoration: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  display: inline;
}

a:link {
  color: black;
  text-decoration: none;
}

a:visited {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

p {
  margin: 0px;
  white-space: pre-wrap;
  margin-bottom: 0px !important;
  font-size: var(--font-size);
  
}

iframe {
  width: 99%;
}

input {
  outline: none !important;
  box-sizing: border-box;
  border: 1px solid transparent;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:hover {
  /* box-shadow: 0 0 3px #719ece; */
}

input:focus {
  /* box-shadow: 0 0 3px #719ece; */
}

input::placeholder {
  opacity: 0.6;
}

input:focus::placeholder {
  color: transparent;
}

table {
  border-spacing: 0px;
}

img {
  /* width: 100%; */
}

.Toastify__toast-container {
  height: fit-content;
}

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 100% !important;
}

.container-lg {
  padding:0 !important;
}

.body.flex-grow-1.px-3 {
  padding:0 !important;
}

.newSwiper{
  width : 45% !important;
}

.nav-link {
  /* color: black !important; */
}

.table-responsive {
  overflow-x: visible;
}

.accordion-button>div {
  overflow-x: hidden;
}

.CTextArea{
  border-radius: 0;
}

.chartjs-tooltip {
  width: max-content;
}

option{
  padding : none !important;
}


.myDragHandle {
  width: 30px;
  height: 30px;
  
  position: absolute;
  z-index: 1;
}


.react-datepicker__month-container {
  background-color: white;
}
.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}
.react-datepicker__day--keyboard-selected{
  background-color: black;
  border-radius: 50%;
}
.react-datepicker__day--keyboard-selected:hover{
  background-color: black;
  border-radius: 50%;
  
}

.react-datepicker__day--in-range{
  border-radius: 50%;
  background-color: black;
}
.react-datepicker__day--in-range:hover{
  border-radius: 50%;
  background-color: black;
  scale: 1.1;
}
.react-datepicker__day:hover{
  border-radius: 50%;
  background-color: black;
  color: white ;
}
.react-datepicker__day--in-selecting-range{
  border-radius: 50%;
  background-color: black !important;
}
.react-datepicker__day--selected{
  border-radius: 50%;
  background-color: black;
}


.mySwiper .swiper-pagination-progressbar {
  background: #E0E0E0;
  
  width: 35%;
  left: 52% ;
}

.mySwiperMobile .swiper-pagination-progressbar {
  background: #E0E0E0;
  position: relative;
  width: 75%;
  
}

.mySwiper .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: black;
}
.mySwiperMobile .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: black;
}
.custom-pagination {
  display: flex;
  justify-content: center; /* 페이지네이션 가운데 정렬 */
  margin-top: 10px; /* 프로그래스바와의 간격 */
}

.custom-pagination-bullet {
  margin: 0 5px;
  cursor: pointer;
}

.custom-pagination-bullet.active {
  font-weight: bold;
}

.swiper-custom-pagination{
  position: absolute;
  
  top : 24% ;
}
/* 네비게이션 및 페이지네이션 컨테이너 */

.swiper-navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4%; /* 또는 프로그래스바의 세로 위치에 맞게 조정 */
  left: 89%;
  
  width: 10%;
  transform: translateY(-50%); /* 세로 중앙 정렬 */
}

.swiper-navigation-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top:10px ;
  left: 38% ;
  width: 20%;
  
  transform: translateY(-50%); /* 세로 중앙 정렬 */
}

/* 페이지 숫자 위치 조정 */
.swiper-pagination-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 24% ;
  left : 0% ;
  pointer-events: none;
  color : black ;
  font-weight: bold;
  user-select: none;
}
.swiper-pagination-custom-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0 ;
  user-select: none;
  left : 0% ;
  pointer-events: none;
  color : black ;
  font-weight: bold;
  
  
  
}

/* 네비게이션 버튼 위치 조정 */
.swiper-button-prev, .swiper-button-next {
  color: #000; /* 버튼 색상 */
  /* 필요에 따라 추가 스타일 */
  position: absolute;
  text-align: right;
  

}

/* 이전 버튼 */
.swiper-button-prev {
  margin-right: 0px; /* 페이지네이션과의 간격 */
  
  
}

/* 다음 버튼 */
.swiper-button-next {
  margin-left: 0px; /* 페이지네이션과의 간격 */
}

.react-datepicker__month-container{
  width:initial !important;
}

.Mui-checked {
  color : black !important;
}

.box {
  height:100%;
}

.react-tabs {
  height:100%;
  background-color: #F0F0F0;
}

.react-tabs__tab-list {
  background-color: #5D5D5D;
}
.react-tabs__tab {
  color: white;
}

.react-tabs__tab--selected {
  color: black;
  background-color: #F0F0F0;
}
.react-tabs__tab--selected::after{
  content: none;
  display: none;
}
.react-tabs__tab-panel {
  background-color: #F0F0F0;
}
.react-tabs__tab-list {
  margin : 0;
}

.ql-editor{
  overflow-y: visible !important;
}

.paragraph {}

.ellipsis {
    white-space: nowrap; /* 단일 줄로 표시 */
    overflow: hidden; /* 내용이 넘칠 경우 숨김 처리 */
    text-overflow: ellipsis; /* 넘친 내용을 ...으로 표시 */
    width: 100%; /* 필요한 경우, 고정 폭을 설정하거나 부모 요소에 맞게 조정 */
    display: block; /* 올바르게 작동하도록 display 속성 지정 */
}

.hover:hover{
    text-decoration: underline;
}
/* TabView.module.css */
.tabContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  color: white;
  border-bottom: 2px solid transparent;
}

.activeTab {
  border-bottom: 2px solid white;
}

.wrap{
    position: relative;
    transition : all .1s;
    
}
.wrap.vaild{
    transition : all .1s;
}

.wrap.active{
    border-bottom: 2px solid var(--main-color);
    transition : all .1s;
}
.wrap.disabled{
    /* background-color: var(--user-bg-color) !important; */
    
}

.placeHolder{
    position: absolute;
    top: 0px;
    pointer-events: none;
    transition : all .1s;
    padding: 0 0 0 20px;
    color: var(--font-faint-color) ;
}

.placeHolder:focus{
    
}

.placeHolder.active{
    font-weight: bold;
    font-size: 10.5px ;
    color: var(--font-color);
    transition : all .1s;
    
}

.defaultPlaceHolder{
    top: 13px ;
    
}

.readOnly{
    font-weight: bold;
    font-size: 10.5px ;
    color: var(--font-color);
    transition : all .1s;
    top: -10px ;
    
}

.borderBottom{
    border-bottom: 1px solid var(--line-color);
}
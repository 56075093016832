.flex {
  display: flex;
  flex: 1;
  align-items: center;
}

.flexStart {
  display: flex;
  flex: 1;
  align-items: flex-start !important;
}

.vertical {
  flex-direction: column;
}

.vertical > .flexChild {
  flex-direction: column;
}

.horizontal {
  flex-direction: row;
  justify-content: space-between;
  height:100%;
}

.flex > div {
  display: flex;
  flex: 1;
}

.flexChild {
  padding: 0px;
  align-items: center;
}

.flexChild.fixed {
  flex: none;
  overflow: visible;
}

.horizontal > .flexChild {
  /* overflow: visible; */
}

.grid {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  
}
/* 
.horizontal.seperator > .flexChild:first-child {
  border-left: none;
}

.horizontal.seperator > .flexChild {
  border-left: 2px solid #aaa;
} */


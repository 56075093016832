.equilateral {
    width: 100%;
    position: relative;
    height:initial;
    margin: auto;
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &.square {
        .inner {
            position: absolute;
            width: 100%;
            height: 100%;
            
        }
    }

    &.circle {
        border-radius: 50%;
        .inner {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

  
}



.wrap {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
}

.button {
    /* background-image: linear-gradient(94deg,var(--button-color-start),var(--button-color-end)); */
    color: #fff;
    width: initial;
    height: initial;
    text-align: center;
   
}

.button > a{
    color : #fff ;
}

.wrap {
    display : flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: max-content;
}

.label {
    display: inline-block;    
}

.checkbox {
    height:30px;
    background-color: #ffffff;
    
}

input[type=checkbox] {
    display: none;
    
  }

.checkboxIcon{
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid rgb(102,102,102) ;
    background-color: white;
    
    position: relative;
    cursor: pointer;
}
.checkboxIcon::before, .checkboxIcon::after {
    
    content: '';
    position: absolute;
    background-color: transparent;
}
.checkboxIcon::before{
    top: 10px ;
    left : 2px ;
    transform: rotate(-25deg);
}

.checkboxIcon::after{
    top : 28px ;
    left : 12px ;
    transform: rotate(-145deg);
}
.checkbox input:checked + .checkboxIcon {
    border-color: #ff6d00; /* 체크됐을 때의 테두리 색상을 원하는 색상으로 설정합니다. */
    background-color: var(--main-color) ;
}

.checkbox input:checked + .checkboxIcon::before {
    /* 체크박스 선택 시 체크 모양을 표시 */
    top: 48%;
    left: 43%;
    width: 3px;
    height: 18px; /* 체크의 가로 부분 길이 */
    background-color: white; /* 체크 색상 */
    transform: rotate(45deg) translate(-50%, -50%);
}
.checkbox input:checked + .checkboxIcon::after {
    /* 체크박스 선택 시 체크 모양을 표시 */
    top: 46%;
    left: 48%;
    width: 3px;
    height: 14px; /* 체크의 세로 부분 길이 */
    background-color: white; /* 체크 색상 */
    transform: rotate(-45deg) translate(-50%, -50%);
}

.checkboxText{
    padding-left : 10px;
    
}
.radiusBox {
    border: 1px solid white;
    border-radius: 15vw;
    width: auto;
    padding: 2% 13%;
}

.radiusBoxGreen {
    background-color: #73FF8A;
    border: 1px solid #73FF8A;
    border-radius: 15vw;
    width: auto;
    padding: 2% 13%;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 0.025vw solid white;
    border-radius: 1vw;
    padding: 10% 10%;
}

.boxWhite {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 0.025vw solid white;
    border-radius: 1vw;
    padding: 10% 10%;
}

.plus {
    background-color: #73FF8A;
    border-radius: 50%;
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    /* left: -300%; */
}